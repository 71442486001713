<template>
	<div>
        <q-card v-if='status != "FINISHED"' style="text-align:center;">
            <q-card-section>
                Status: {{ status }}
                <div v-if='getUser.role == 2'>
                    <q-btn @click='killMonitor()' label='Kill Monitor' v-if='status == "MONITORING"' />
                </div>
            </q-card-section>
        </q-card>
        <collection v-else>
        </collection>
	</div>
</template>

<script>
import Collection from "./Collection.vue"
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters([
			'isLoggedIn',
			'getUser'
		]),
	},

    components: {
        Collection
    },
	data() {
		return {
			status: "...",
            intervalId: 0
		};
	},
	async created() {
        this.fetchStatus();
        this.intervalId = setInterval(this.fetchStatus.bind(this), 500);
	},
	methods: {
        async killMonitor () {
            const response = await this.$http.post(`/collection/${this.$route.params.contract_address}/kill`);
            if (!response.data.success) {
                return alert("Something wrong happened: " + response.data.message);
            }

            console.log(response.data);
            alert("Successfully sent kill monitor signal");
        },
		async fetchStatus() {
            console.log(this.$route.name)
            if (this.$route.name != "Collection") {
                clearInterval(this.intervalId);
                return;
            }

			const response = await this.$http.get(`/collection/${this.$route.params.contract_address}/status`);
            if (!response.data.success) {
                clearInterval(this.intervalId);
                return alert("Something wrong happened: " + response.data.message);
            }

            this.status = response.data.data;
            if (this.status === "FINISHED") {
                clearInterval(this.intervalId);
            }
		}
	}
};
</script>
