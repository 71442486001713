<template>
	<q-card bordered>
		<template v-if="autoload_image || (load_image || default_image == false)">
			<q-img style='cursor:pointer;' :src="image" @click='$emit("popupDialog")'/>
		</template>
		<template v-else>
			<q-img style='cursor:pointer;' :src="default_image" @click="load_image = true"/>
		</template>

		<q-card-section>
			<div class="fit row wrap justify-between items-start content-start">
				<div class="col">
					<div class="text-overline text-blue-9">
						ID {{ NFT.token_id }}
					</div>
				</div>
				<div class="col">
					<div class="text-overline text-right text-blue-9">
						Rank #{{ NFT.rank }}
					</div>
				</div>
			</div>

			{{ NFT.name }}

			<div class="text-caption text-grey">
				Rarity Score: {{ NFT.rarity_score.toFixed(2) }} <br>
				<template v-if='price == -1'>
					<span class="text-red">Not for Sale</span>
				</template>
				<template v-else>
					<span class="text-blue text-bold">Price: {{ price }} ETH</span>
				</template>
        	</div>
		</q-card-section>

		<q-card-actions>
			<q-btn
				flat
				color="dark"
				label="OpenSea"
				type="a"
				:href="'http://opensea.io/assets/' + contract_address + '/' + NFT.token_id"
				target="_blank"
			/>

			<q-space />

			<q-btn
				color="grey"
				round
				flat
				dense
				:icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
				@click="expanded = !expanded"
			/>
		</q-card-actions>

		<q-slide-transition>
			<div v-show="expanded">
				<q-separator />
				<q-card-section class="text-subitle2">
					<div v-for="(attr, index) in NFT.attributes" :key='index'>
						<div class="fit row wrap justify-between items-start content-start">
							<div class="col">
								<div class="ellipsis text-uppercase" :title='attr.trait_type'>
									{{ attr.trait_type }}
								</div>
							</div>
							<div class="col">
								<div class="ellipsis text-uppercase text-blue-5 text-right" :title='attr.value'>
									{{ attr.value }} 
								</div>
							</div>
						</div>
					</div>
				</q-card-section>
			</div>
		</q-slide-transition>
	</q-card>
</template>
<script>
const IPFS_PREFIX = "ipfs://";
const AR_PREFIX = "ar://";

export default {
	props: ["NFT", "price", "contract_address", "default_image", "autoload_image"],
	data () {
		return {
			expanded: false,
			load_image: false
		}
	},
	computed: {
		image: function () {
			if (this.NFT.image.startsWith(IPFS_PREFIX)) {
				return (
					"https://ipfs.io/ipfs/" +
					this.NFT.image.substr(IPFS_PREFIX.length)
				);
			} else if (this.NFT.image.startsWith(AR_PREFIX)) {
				return (
					"https://arweave.net/" +
					this.NFT.image.substr(AR_PREFIX.length)
				);
			}

			if (this.NFT.image.startsWith("https://gateway.pinata.cloud/")) {
				return (
					"https://ipfs.io/" +
					this.NFT.image.substr("https://gateway.pinata.cloud/".length)
				);
			}

			return this.NFT.image;
		}
	},
};
</script>