<template>
	<div class="container">
		<q-card class="my-card">
			<q-banner v-if="error.length > 0" inline-actions class="text-white bg-red">
				{{ error }}
			</q-banner>
			<q-form @submit="onSubmit" class="q-gutter-md">
				<q-input
					filled
					v-model="contract_address"
					label="Contract Address"
					lazy-rules
					:rules="[
						(val) =>
							(val &&
								val.length === 42 &&
								val[0] === '0' &&
								val[1] === 'x') ||
							'Please type a valid contract address',
					]"
				/>
				
				<div v-if='getUser.role == 2'>
					<span>Admin Settings</span>
					<br>
					<q-checkbox v-model="manual_totalSupply_amount" label="Manual Total Supply Amount" />
					<q-checkbox v-model="manual_starting_index" label="Custom Starting Index" />
					<q-checkbox v-model="options.force_tokenuri" label="Force tokenURI" />

					<q-input
						v-if='manual_totalSupply_amount'
						filled
						type="number"
						v-model="options.totalSupply_amount"
						label="totalSupply amount"	
					/>

					<q-input
						v-if='!manual_totalSupply_amount'
						filled
						v-model="options.totalSupply_function"
						label="totalSupply function name"	
					/>

					<br>

					<q-input
						v-if='manual_starting_index'
						filled
						type="number"
						v-model="options.starting_index"
						label="Starting Index"
					/>
					
					<br>
					<q-input
						filled
						v-model="options.setBaseURI_function"
						label="setBaseURI function name"
					/>
				</div>

				<div>
					<q-btn label="Submit" type="submit" color="primary" />
				</div>
			</q-form>
		</q-card>
	</div>
</template>

<script>
import {  mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters([
			'isLoggedIn',
			'getUser'
		]),
	},

	data() {
		return {
			contract_address: "",
			manual_totalSupply_amount: false,
			manual_starting_index: false,
			options: {
				totalSupply_function: "totalSupply",
				setBaseURI_function: "setBaseURI",
				totalSupply_amount: 5000,
				monitor_pending_transactions: false,
				force_tokenuri: false,
				starting_index: 1
			},
			error: "",
		};
	},
	created() {},
	methods: {
		async onSubmit() {
			let payload = {
				contract_address: this.contract_address
			};

			if (this.getUser.role === 2) {
				payload.options = {
					setBaseURI_function: this.options.setBaseURI_function,
					force_tokenuri: this.options.force_tokenuri,
					monitor_pending_transactions: this.options.monitor_pending_transactions,
				};

				if (this.manual_totalSupply_amount) {
					payload.options.totalSupply_amount = parseInt(this.options.totalSupply_amount);
				} else {
					payload.options.totalSupply_function = this.options.totalSupply_function;
				}

				if (this.manual_starting_index) {
					payload.options.starting_index = parseInt(this.options.starting_index);
				}
			}

			const response = await this.$http.post("/snipe", payload);

			if (!response.data.success) {
				this.error = "Something wrong happened: " + response.data.message;
				return;
			}

			this.$router.push({
				name: "Collection",
				params: {
					contract_address: this.contract_address
				}
			})
		},
	},
};
</script>
<style scoped>
.container {
	padding-top: 1rem;
	padding-left: 5rem;
	padding-right: 5rem;
}
.my-card {
	padding: 1rem;
}
</style>