<template>
	<div class="home">
		<div class="row">
			<div v-for="collection in collections" :key="collection.contract_address" class="col-3 collection-card">
				<collection-mini-card :collection="collection"></collection-mini-card>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import CollectionMiniCard from '@/components/CollectionMiniCard.vue'
import { useQuasar } from 'quasar'

export default {
	components: {
		CollectionMiniCard
	},
	data () {
		return {
			collections: []
		}
	},
	created () {
		const $q = useQuasar()

		$q.loading.show()
		this.$http.get("/collections?page=0").then(response => {
			if (response.data.success) {
				this.collections = response.data.data;
			}
		}).catch(err => {
			console.log(err);
			alert("Error fetching collections: " + err.toString());
		}).finally(() => {
			$q.loading.hide();
		})
	}
}
</script>
<style scoped>
.collection-card {
	padding: 1rem;
}
</style>