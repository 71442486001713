<template>
	<div class="container">
		<q-card class="my-card">
			<q-banner v-if="error.length > 0" inline-actions class="text-white bg-red">
				{{ error }}
			</q-banner>
			<q-form @submit="onSubmit" class="q-gutter-md">
				<q-toggle label="Load all images by default" v-model="load_all_images"/>
				<div>
					<q-btn label="Submit" type="submit" color="primary" />
				</div>
			</q-form>
		</q-card>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	data() {
		let stored_data_str = localStorage.getItem("preferences");
		let settings = {
			load_all_images: false,
			for_sale: false
		};

		if (stored_data_str !== null) {
			settings = JSON.parse(stored_data_str);
		}

		return {
			load_all_images: false,
		};
	},
	created() {},
	methods: {
		async onSubmit() {
			
		},
	},
};
</script>
<style scoped>
.container {
	padding-top: 1rem;
	padding-left: 5rem;
	padding-right: 5rem;
}
.my-card {
	padding: 1rem;
}
</style>