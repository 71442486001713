<template>
	<q-card style='width:100%;height:100%'>
		<q-card-section style="padding:0px;" class="q-pt-none">
			<q-table
				binary-state-sort
				:rows="rows"
				:columns="columns"
				:pagination='{ sortBy: "occurrences", rowsPerPage: 0 }'
				/>
		</q-card-section>
	</q-card>
</template>
<script>
export default {
	props: ["occurrences"],
	created () {
		for (const [trait_type, values] of Object.entries(this.occurrences)) {
			for (const [value, occurrences] of Object.entries(values)) {
				this.rows.push({
					trait: trait_type,
					value: value,
					occurrences
				})
			}
		}
	},
	data() {
		return {
			rows: [],
			columns: [{
				name: 'trait',
				label: 'Trait',
				field: 'trait',
				align: 'left',
				sortable: true
			}, {
				name: 'value',
				label: 'Value',
				field: 'value',
				align: 'left',
				sortable: true
			}, {
				name: 'occurrences',
				label: 'Occurrences',
				field: 'occurrences',
				align: 'right',
				sortable: true
			}]
		};
	},
};
</script>