<template>
	<div>
		<q-dialog v-model="dialogLookupByID">
			<nft-dialog
				:NFT="lookup_by_id_data"
				:contract_address="collection.contract_address"

				:default_image="false"

				:price="lookup_by_id_data.token_id in prices ? prices[lookup_by_id_data.token_id] : -1"

				:rarity_scores="collection.rarity_scores"
			></nft-dialog>
		</q-dialog>
		<q-dialog full-height v-model='openOccurrencesDialog'>
			<occurrences-dialog :occurrences="collection.occurrences"/>
		</q-dialog>
		<div class="row">
			<div class="col" style="padding: 1rem">
				<q-toolbar class="bg-primary text-white q-my-md shadow-2">
					<q-btn-dropdown stretch flat label="Actions">
						<q-list>
							<q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="refreshPrices"
							>
								<q-item-section>
									<q-item-label>Refresh Prices</q-item-label>
								</q-item-section>
							</q-item>
							<!-- <q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="refresh_attributes"
							>
								<q-item-section>
									<q-item-label>Refresh Attributes</q-item-label>
								</q-item-section>
							</q-item> -->
							
						</q-list>
					</q-btn-dropdown>
					<q-separator dark vertical />
					<q-separator dark vertical />
					<form @submit.prevent='lookupByID'>
						<q-input
							style="margin-left: 1rem"
							placeholder="Lookup ID"
							standout="bg-primary text-white"
							label-color="white"
							color="white"
							v-model="lookupByID_input"
							dense
						/>
					</form>
					<q-input
						style="margin-left: 1rem"
						placeholder="Minimum Price (ETH)"
						standout="bg-primary text-white"
						label-color="white"
						color="white"
						v-model="minimumPrice_input"
						dense
					/>
					<q-input
						style="margin-left: 1rem"
						placeholder="Maximum Price (ETH)"
						standout="bg-primary text-white"
						label-color="white"
						color="white"
						v-model="maximumPrice_input"
						dense
					/>
					<q-space />

					<q-btn
						color="white"
						flat
						label="Show Occurrences"
						@click="openOccurrencesDialog = true"
					/>
					<!-- 
					<q-separator dark vertical/>
					<q-btn-dropdown stretch flat label="Sort By">
						<q-list>
							<q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="sort_by = 'PRICE_ASC'"
							>
								<q-item-section>
									<q-item-label
										>Price: Low -> High</q-item-label
									>
								</q-item-section>
							</q-item>
							<q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="sort_by = 'PRICE_DESC'"
							>
								<q-item-section>
									<q-item-label
										>Price: High -> Low</q-item-label
									>
								</q-item-section>
							</q-item>
							<q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="sort_by = 'RARITY'"
							>
								<q-item-section>
									<q-item-label>Rarity</q-item-label>
								</q-item-section>
							</q-item>
							<q-item
								clickable
								v-close-popup
								tabindex="0"
								@click="sort_by = 'ID'"
							>
								<q-item-section>
									<q-item-label>ID</q-item-label>
								</q-item-section>
							</q-item>
						</q-list>
					</q-btn-dropdown>
						-->
					<q-separator dark vertical />
					<q-btn-dropdown stretch flat label="Filter">
						<q-list>
							<q-item
								clickable
								tabindex="0"
								@click="
									filters.for_sale = !filters.for_sale
								"
							>
								<q-item-section>
									<q-item-label>For Sale</q-item-label>
								</q-item-section>
								<q-item-section side>
									<q-checkbox
										v-model="filters.for_sale"
									/>
								</q-item-section>
							</q-item>
							<q-item
								clickable
								tabindex="0"
								@click="
									autoload_images = !autoload_images
								"
							>
								<q-item-section>
									<q-item-label>Load all images</q-item-label>
								</q-item-section>
								<q-item-section side>
									<q-checkbox
										v-model="autoload_images"
									/>
								</q-item-section>
							</q-item>
						</q-list>
					</q-btn-dropdown>
				</q-toolbar>
			</div>
		</div>

		<div class="row">
			<div
				v-for="(NFT, index) in filtered"
				:key="NFT.token_id"
				class="col-2 collection-card"
			>
				<nft-mini-card
					:autoload_image="autoload_images"
					@popupDialog="popupNFT(index)"
					:NFT="NFT"
					:contract_address="collection.contract_address"
					:default_image="collection_thumbnail"
					:price="NFT.token_id in prices ? prices[NFT.token_id] : -1"
				></nft-mini-card>
			</div>
		</div>
		<div style="text-center">
			<q-btn color="blue" flat label="Load More" @click="fetchNFTs" />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import NftMiniCard from "@/components/NFTMiniCard.vue";
import NftDialog from "@/components/NFTDialog.vue";
import { useQuasar } from "quasar";
import OccurrencesDialog from '../components/OccurrencesDialog.vue';

export default {
	components: {
		NftMiniCard,
		NftDialog,
		OccurrencesDialog
	},
	data() {
		return {
			filters: {
				for_sale: false,
			},
			collection_thumbnail: "",
			sort_by: "RARITY",
			local_sort_by: "PRICE_ASC",
			collection: {},
			NFTs: [],
			prices: {},
			page: 0,
			fetching_nfts: false,

			dialogLookupByID: false,
			lookupByID_input: "",

			minimumPrice_input: "",
			maximumPrice_input: "",

			lookup_by_id_data: {},

			openOccurrencesDialog: false,
			autoload_images: false
		};
	},
	async created() {
		const $q = useQuasar();
		$q.loading.show();

		try {
			const response = await this.$http.get(`/collection/${this.$route.params.contract_address}/info`);

			$q.loading.hide();
			
			if (!response.data.success) {
				this.$router.push({
					name: "Home",
				});

				return;
			}

			this.collection = response.data.data;

			if (this.collection.opensea_data.image_url !== null) {
				if (this.collection.opensea_data.image_url.includes("googleusercontent.com") && this.collection.opensea_data.image_url.endsWith("s120")) {
					this.collection_thumbnail = this.collection.opensea_data.image_url.slice(0, -5);
				} else {
					this.collection_thumbnail = this.collection.opensea_data.image_url;
				}
			} else {
				this.collection_thumbnail = "https://cdn.quasar.dev/img/parallax2.jpg";
			}

			this.initialFetch();
		} catch (e) {
			console.error("Something wrong happened fetching data", e)
		} finally {
			$q.loading.hide();
		}
	},
	methods: {
		lookupByID() {
			const to_int = parseInt(this.lookupByID_input);
			if (isNaN(to_int)) {
				alert("ID has to be a number");
				return;
			}
			
			this.$http.get(`/collection/${this.$route.params.contract_address}/nft/${to_int}`).then((response => {
				if (response.data.success) {
					this.dialogLookupByID = true;
					this.lookup_by_id_data = response.data.data;
				} else {
					alert("Something wrong happened: " + response.data.message);
				}
			}));
		},
		popupNFT(index) {
			this.dialogLookupByID = true;
			this.lookup_by_id_data = this.NFTs[index];
		},
		refresh_attributes() {
			this.$http
				.post(`/collection/${this.$route.params.contract_address}/refresh_attributes`)
				.then((response) => {
					if (response.data.success) {
						alert(response.data.message);
					} else {
						if ("message" in response.data) {
							alert(response.data.message);
						} else {
							alert("Something wrong happened");
						}
					}
				});
		},
		refreshPrices() {
			this.$http
				.post(`/collection/${this.$route.params.contract_address}/refresh_prices`)
				.then((response) => {
					if (response.data.success) {
						alert(response.data.message);
					} else {
						if ("message" in response.data) {
							alert(response.data.message);
						} else {
							alert("Something wrong happened");
						}
					}
				});
		},
		initialFetch() {
			this.fetchNFTs();
			this.fetchPrices();

			window.onscroll = () => {
				let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
				if (bottomOfWindow) {
					this.fetchNFTs();
				}
			};
		},
		fetchPrices() {
			this.$http
				.get(
					`/collection/${this.$route.params.contract_address}/prices`
				)
				.then((response) => {
					if (response.data.success) {
						this.prices = response.data.data;
					} else {
						console.error("Error getting prices");
					}
				});
		},
		fetchNFTs() {
			if (this.fetching_nfts) return;

			this.fetching_nfts = true;

			let query_params = "";
			if (this.filters.for_sale) {
				query_params += "&for_sale_filter=true";
			}

			this.$http.get(`/collection/${this.$route.params.contract_address}/NFTs?page=${this.page}${query_params}`)
				.then((response) => {
					this.fetching_nfts = false;
					this.page++;

					if (response.data.success) {
						this.NFTs.push(...response.data.data);
					}

					if (this.filtered.length <= 10) {
						this.fetchNFTs();
					}
				});
		},
		price_filter (nft) {
			const nft_price = this.prices[nft.token_id];

			let passed_min_price = true;
			if (this.minimumPrice_input !== "") {
				const price = parseFloat(this.minimumPrice_input);
				if (!isNaN(price) && parseFloat(nft_price) < price){
					passed_min_price = false;
				}
			}

			let passed_max_price = true;
			if (this.maximumPrice_input !== "") {
				const price = parseFloat(this.maximumPrice_input);
				if (!isNaN(price) && parseFloat(nft_price) > price){
					passed_max_price = false;
				}
			}

			return passed_min_price && passed_max_price;
		}
	},
	computed: {
		filtered: function () {
			return this.NFTs.filter(this.price_filter.bind(this));
		}
	},
	watch: {
		filters: {
			handler: function (val, oldVal) {
				this.page = 0;
				this.fetching_nfts = false;
				this.NFTs = [];
				if (val.for_sale) {
					this.fetchPrices();
				}

				this.fetchNFTs();
			},
			deep: true,
		},
		maximumPrice_input () {
			if (this.filtered.length <= 30) {
				this.fetchNFTs();
			}
		},
		minimumPrice_input () {
			if (this.filtered.length <= 30) {
				this.fetchNFTs();
			}
		}
	},
};
</script>
<style scoped>
.collection-card {
	padding: 1rem;
}
</style>