import axios from "axios";
import Vue from "vue";

let instance = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? "http://127.0.0.1:5000/" : "https://api.nftsniper.ai/",
	headers: {
		"Authorization": localStorage.getItem("token")
	}
});

instance.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	if (token === null) {
		return config;
	}

	config.headers.Authorization = token;

	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

export default instance;