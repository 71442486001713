import { createRouter, createWebHashHistory } from 'vue-router'

import NewSnipe from '../views/NewSnipe.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import Collections from '../views/Collections.vue'
import RootCollection from '../views/RootCollection.vue'
import Settings from '../views/Settings.vue'

const routes = [
	{
		path: '/collections',
		name: 'Collections',
		component: Collections
	},
	{ 
		path: '/collection/:contract_address',
		name: "Collection",
		component: RootCollection
	},
	{ 
		path: '/settings',
		name: "Settings",
		component: Settings
	},
	{
		path: '/',
		redirect: { 
			name: 'Collections'
		}
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/new',
		name: 'NewSnipe',
		component: NewSnipe
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
