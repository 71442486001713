<template>
	<q-page class="flex flex-center">
		<h4>Logging in ...</h4>
	</q-page>
</template>

<script>
import { defineComponent } from "vue";
import { ethers } from "ethers";
import { mapGetters, mapMutations } from "vuex";

const PREFIX_MESSAGE = "Welcome to Astra!\n" +
    "Click the Sign button log in.\n\n" +
	"This request will NOT trigger a blockchain transaction or cost any fees.\n\n" +
    "Unique Login Token: ";

export default defineComponent({
	name: "PageLogin",
	mounted () {
		if (this.isLoggedIn) {
			this.$router.push("/");
		} else {
			this.loginWithMetaMask();
		}
	},
	computed: {
		...mapGetters({
			isLoggedIn: "isLoggedIn"
		})
	},
	methods: {
		...mapMutations({
			setLoginData: "SET_LOGGED_IN"
		}),
		async login (address, nonce, signature) {
			try {
				const response = await this.$http.post("/astra_web/login", {
					address,
					nonce,
					signature
				});

				if (response.data.success) {
					console.log("Successfully logged in");
					this.setLoginData({
                        username: address,
                        token: response.data.data,
                        role: 1
                    });

					const redirect_url = "redirect" in this.$route.query ? this.$route.query.redirect : "/";
					this.$router.push(redirect_url);
				} else {
					alert("An error occurred: " + response.data.message);
					location.reload();
				}
			} catch (e) {
				alert("An error occurred: " + e.toString());
			}
		},
		async loginWithMetaMask () {
			if (typeof window.ethereum === "undefined") {
				alert("MetaMask is not installed. Please install it from your browser's app store.");
				return;
			}

			const [wallet_address] = await window.ethereum.request({
				method: "eth_requestAccounts"
			});

            const nonce = parseInt(Date.now() / 1000);
			const provider = new ethers.providers.Web3Provider(window.ethereum);

            try {
				const signature = await provider
					.getSigner()
					.signMessage(`${PREFIX_MESSAGE}${nonce}`);
				await this.login(wallet_address, nonce, signature);
			} catch (e) {
				this.$q.notify({
					color: "red",
					message: "An error occurred: " + e.message
				});

				this.$router.push({
					name: "Collections",
				});
			}
		}
	}
});
</script>
