<template>
	<q-layout view="hHh LpR fFf">
		<q-header elevated class="bg-primary text-white" height-hint="98">
			<q-toolbar>
				<!-- <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" /> -->

				<q-toolbar-title style='cursor:pointer;' @click='gotoCollections()'>
					<q-avatar>
						<img src="./assets/logo.png" />
					</q-avatar>
					<span>NFT Sniper</span>
				</q-toolbar-title>

				<template v-if='isLoggedIn'>
					<q-btn flat round dense icon="add" class="q-mr-xs" :to="{ name: 'NewSnipe' }" />
					<!-- <q-btn flat round dense icon="settings" class="q-mr-xs" :to="{ name: 'Settings' }" /> -->
					<!-- <q-btn flat round dense icon="search" class="q-mr-xs" /> -->
					<q-btn flat round dense icon="logout" class="q-mr-xs" @click="LOGOUT"/>
				</template>
				<template v-else>
					<q-btn class="q-mr-xs" @click="login">Login</q-btn>
				</template>
			</q-toolbar>

			<!--
				<q-tabs align="left">
					<q-route-tab to="/page1" label="Collection #1" />
					<q-route-tab to="/page2" label="Collection #22" />
					<q-route-tab to="/page3" label="Collection #45" />
				</q-tabs>
			-->
		</q-header>

		<!-- <q-drawer v-model="isSideBarOpen" side="left" bordered style="padding-top: 1rem">
			<q-list separator>
				<q-item v-ripple clickable v-if='isLoggedIn'>
					<q-item-section>
						<q-item-label>Welcome {{ getUser.username }}</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable v-ripple :to="{ name: 'Collections' }">
					<q-item-section>
						<q-item-label>Collections</q-item-label>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="gotoDiscord()">
					<q-item-section>
						<q-item-label>Discord</q-item-label>
						<q-item-label caption>Join our discord for more info</q-item-label>
					</q-item-section>
				</q-item>
			</q-list>
		</q-drawer> -->

		<q-page-container>
			<router-view />
		</q-page-container>
	</q-layout>
</template>

<script>
import { ref } from "vue";
import { mapMutations, mapGetters } from "vuex";
import { useQuasar } from 'quasar'

export default {
	methods: {
		...mapMutations(["SET_LOGGED_IN", "LOGOUT"]),
		gotoCollections () {
			this.$router.push({
				name: "Collections"
			})
		},
		gotoDiscord () {
			location.href = "https://discord.gg/TR4249KG6K";
		},
		login () {
			this.$router.push({
				name: "Login"
			})
		}
	},
	computed: {
		...mapGetters([
			'isLoggedIn',
			'getUser'
		]),
	},
	setup() {
		let isSideBarOpen = ref(true);

		let ls_item = localStorage.getItem("sidebar_open");
		if (ls_item !== null) {
			isSideBarOpen.value = ls_item == "false" ? false : true;
		}

		return {
			isSideBarOpen,
			toggleLeftDrawer() {
				isSideBarOpen.value = !isSideBarOpen.value;
				localStorage.setItem("sidebar_open", isSideBarOpen.value);
			},
		};
	},
	created () {
		let local_storage_token = localStorage.getItem("token");
		if (local_storage_token === null) return;

		const $q = useQuasar();
		$q.loading.show();

		this.$http.post('/astra_web/token', { token: local_storage_token }).then(response => {
			$q.loading.hide();

			if (!response.data.success) {
				localStorage.removeItem("token");
				location.reload();
			} else {
				this.SET_LOGGED_IN({
					username: response.data.data.username,
					token: local_storage_token,
					role: response.data.data.role
				})
			}
		})
	}
};
</script>