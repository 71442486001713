<template>
	<q-card>
		<q-img :src="os_image">
			<div class="absolute-bottom">
				<div class="text-h6">
					{{ collection.opensea_data.collection.name }} <br>
				</div>
				<div v-if='collection.status === "FINISHED"' class="text-subtitle2">{{ collection.total_nfts }} NFTs</div>
				<div v-else>Status: <span class="text-bold">{{ collection.status }}</span></div>
			</div>
		</q-img>
		<q-card-actions>
			<q-btn :to="{ name: 'Collection', params: { contract_address: collection.contract_address } }" flat>View Rarity Scores</q-btn>
			<q-btn type="a" :href="os_url" flat target="_blank">OpenSea Page</q-btn>
		</q-card-actions>
    </q-card>
</template>
<script>
export default {
	props: ['collection'],
	computed: {
		os_url: function () {
			if ("collection" in this.collection.opensea_data) {
				return 'https://opensea.io/collection/' + this.collection.opensea_data.collection.slug;
			}

			return 'https://opensea.io/assets/' + this.collection.contract_address + "/1";
		},

		os_image: function () {
			if ("image_url" in this.collection.opensea_data) {
				if (this.collection.opensea_data.image_url === null) {
					return "https://cdn.quasar.dev/img/parallax2.jpg";
				}

				// Google images thing
				if (this.collection.opensea_data.image_url.includes("googleusercontent.com") && this.collection.opensea_data.image_url.endsWith("s120")) {
					return this.collection.opensea_data.image_url.slice(0, -5);
				}

				return this.collection.opensea_data.image_url;
			}

			return "https://cdn.quasar.dev/img/parallax2.jpg";
		}
	}
}
</script>