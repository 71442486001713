import { createStore } from 'vuex'

export default createStore({
	state: {
		logged_in: false,
		user: {
			token: "",
			username: "",
			role: 0
		}
	},
	mutations: {
		SET_LOGGED_IN(state, user) {
			state.logged_in = true;
			state.user = user;

			localStorage.setItem("token", user.token);
		},
		LOGOUT(state) {
			state.logged_in = false;

			localStorage.removeItem("token");
		}
	},
	getters: {
		isLoggedIn: (state) => {
			return state.logged_in;
		},
		getUser: (state) => {
			return state.user;
		}
	},
	actions: {

	},
	modules: {
	}
})
